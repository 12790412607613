export default {
  //登录
  login: '/mall/login',

  // 首屏广告
  adsList: '/mall/ads/list',
  // 添加首屏广告
  adsSave: '/mall/ads/save',
  // 删除首屏广告
  adsDelete: '/mall/ads/delete',
  // 提交首屏广告去审核
  adsSubmit: '/mall/ads/submit',
  //获取七牛云
  getUploadToken: '/mall/qiniu/getUploadToken',


}